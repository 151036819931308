const styles = {
  // Espacements généraux
  paddingX: "sm:px-16 px-6",
  paddingY: "sm:py-16 py-6",
  padding: "sm:px-16 px-6 sm:py-16 py-10",

  // Texte principal dans Hero ajusté
  // src/styles.js (ou dans le fichier de style correspondant)
  heroHeadText:
    "font-black text-white lg:text-[60px] sm:text-[50px] xs:text-[40px] text-[30px] lg:leading-[70px] mt-2",
  heroSubText: "text-lg md:text-xl text-gray-300 leading-relaxed",

  // Sections générales
  sectionHeadText:
    "text-white font-black md:text-[60px] sm:text-[50px] xs:text-[40px] text-[30px]",
  sectionSubText:
    "sm:text-[18px] text-[14px] text-secondary uppercase tracking-wider",

  // Styles spécifiques pour Hero
  heroContainer: "relative w-full h-screen mx-auto overflow-hidden",
  heroInnerContainer:
    "absolute inset-0 top-[120px] max-w-7xl mx-auto flex flex-row items-start gap-5",
  heroIconContainer: "flex flex-col justify-center items-center mt-5",
  heroIcon:
    "w-5 h-5 border-4 border-[#3498db] rounded-full bg-transparent shadow-lg ring-1 ring-white ring-opacity-20 pulse",
  heroLine: "w-1 sm:h-80 h-40 bg-transparent zigzag-line",

  // Bouton de défilement
  scrollButtonContainer:
    "absolute xs:bottom-10 bottom-32 w-full flex justify-center items-center",
  scrollButton:
    "w-[35px] h-[64px] rounded-3xl border-4 border-secondary flex justify-center items-start p-2",
  scrollIndicator: "w-3 h-3 rounded-full bg-secondary mb-1",
};

export { styles };
