import React from "react";

const CallToAction = () => {
  const handleClick = () => {
    window.open("https://calendly.com/astradev/30min?month=2024-11", "_blank");
  };

  return (
    <section className="py-12">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-4xl font-bold text-white mb-6">
          Contactez-nous pour discuter de votre projet
        </h2>
        <p className="text-xl text-white mb-6">
          Réservez un appel gratuit pour discuter de votre projet avec notre
          équipe de développeurs web freelance.
        </p>
        <button
          onClick={handleClick}
          className="bg-white text-black font-bold py-3 px-6 rounded-full hover:bg-gray-300 transition duration-300 mb-6"
        >
          Réservez votre appel gratuit
        </button>
        <p className="text-white transition-all duration-300 ease-in-out relative group">
          Autrement, vous pouvez nous contacter par e-mail à{" "}
          <a
            href="mailto:contact@astra-dev.fr"
            className="text-white-600 underline hover:text-white-700" // Couleur avec plus de contraste et soulignement pour plus de distinction
          >
            contact@astra-dev.fr
          </a>
          .
        </p>
        <p className="text-white text-lg mt-6">
          Ou profitez de la vue de l'espace pour remplir notre formulaire de
          contact ci-dessous <span className="text-yellow-400">✨</span>
        </p>
      </div>
    </section>
  );
};

export default CallToAction;
