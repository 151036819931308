import { Suspense, useEffect, useState } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Preload, useGLTF } from "@react-three/drei";
import Loader from "../Loader";

const Computers = ({ isMobile }) => {
  const computer = useGLTF("/astra/astra.gltf");

  return (
    <mesh>
      {/* Augmentation de l'éclairage ambiant pour un éclairage global léger */}
      <ambientLight intensity={0.8} />
      {/* DirectionalLight principale (angle supérieur droit) avec intensité renforcée */}
      <directionalLight intensity={2} position={[5, 5, 5]} castShadow />
      {/* SpotLight renforcée pour une focalisation encore plus lumineuse */}
      <spotLight
        intensity={2}
        position={[-5, 10, 5]}
        angle={0.3}
        penumbra={1}
        castShadow
        shadow-mapSize-width={2048}
        shadow-mapSize-height={2048}
      />
      {/* PointLights renforcées pour un éclairage équilibré */}
      <pointLight intensity={1.5} position={[10, -5, 10]} />{" "}
      {/* Avant côté droit */}
      <pointLight intensity={1.5} position={[-10, -5, -10]} />{" "}
      {/* Arrière côté gauche */}
      {/* DirectionalLight opposée avec intensité augmentée */}
      <directionalLight intensity={1.5} position={[-5, 5, -5]} castShadow />
      {/* Modèle GLTF */}
      <primitive
        object={computer.scene}
        scale={isMobile ? 0.9 : 0.75}
        position={isMobile ? [0, -2.8, -2.2] : [0, -1.0, -1.5]}
        rotation={[-0.4, 3, -0.1]}
      />
    </mesh>
  );
};

const ComputersCanvas = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 500px)");
    setIsMobile(mediaQuery.matches);

    const handleMediaQueryChange = (event) => {
      setIsMobile(event.matches);
    };
    mediaQuery.addEventListener("change", handleMediaQueryChange);
    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  return (
    <Canvas
      shadows
      camera={{ position: [20, 3, 5], fov: 25 }}
      gl={{ preserveDrawingBuffer: true }}
    >
      <Suspense fallback={<Loader />}>
        <OrbitControls
          autoRotate
          enableZoom={false}
          maxPolarAngle={Math.PI / 2}
          minPolarAngle={Math.PI / 2}
        />
        <Computers isMobile={isMobile} />
      </Suspense>
      <Preload all />
    </Canvas>
  );
};

export default ComputersCanvas;
