import { motion } from "framer-motion";
import { styles } from "../styles.js";
import { ComputersCanvas } from "./canvas";

const Hero = () => {
  return (
    <section className="relative w-full h-screen mx-auto">
      <div
        className={`${styles.paddingX} absolute inset-0 top-[120px] max-w-7xl mx-auto flex flex-row items-start gap-5`}
      >
        <div className="flex flex-col justify-center items-center mt-5">
          <div className="w-5 h-5 border-4 border-[#3498db] rounded-full bg-transparent shadow-lg ring-1 ring-white ring-opacity-20 pulse" />
          <div className="w-1 sm:h-80 h-40 bg-transparent zigzag-line" />
        </div>

        <div>
          <h1
            className="text-white font-bold text-4xl md:text-6xl lg:text-[60px] leading-tight"
            aria-label="Astra Dev - Développeur Web Freelance"
          >
            Astra Dev - <span className="text-[#ff4d4d]">Développeur Web</span>{" "}
            <span className="text-lg text-blue-400 md:text-xl lg:text-2xl">
              Freelance
            </span>
          </h1>
          <p
            className={`${styles.heroSubText} mt-4 text-white leading-relaxed text-base sm:text-lg md:text-xl`}
          >
            Un site web conçu pour{" "}
            <span className="font-semibold text-purple-300">
              attirer et fidéliser
            </span>{" "}
            vos clients.
            <span className="text-blue-700 block mt-3 font-medium inline-block">
              Concentrez-vous sur l'essentiel,{" "}
              <span className="text-green-400">
                nous nous occupons de la technique.
              </span>
            </span>
          </p>
        </div>
      </div>
      <ComputersCanvas />
      <div className="absolute xs:bottom-10 bottom-32 w-full flex justify-center items-center">
        <a
          href="#histoire"
          onClick={(e) => {
            e.preventDefault();
            document.querySelector("#histoire")?.scrollIntoView({
              behavior: "smooth",
            });
          }}
        >
          <div className="w-[35px] h-[64px] rounded-3xl border-4 border-secondary flex justify-center items-start p-2">
            <motion.div
              animate={{ y: [0, 24, 0] }}
              transition={{
                duration: 1.5,
                repeat: Infinity,
                repeatType: "loop",
              }}
              className="w-3 h-3 rounded-full bg-secondary mb-1"
            />
          </div>
        </a>
      </div>
    </section>
  );
};

export default Hero;
