export const navLinks = [
  {
    id: "histoire",
    title: "Notre histoire",
  },
  {
    id: "services",
    title: "Services",
  },
  {
    id: "contact",
    title: "Contact",
  },
];

const services = [
  {
    title: "Site Vitrine",
    icon: "/assets/website.png",
  },
  {
    title: "E-commerce",
    icon: "/assets/ecommerce.png",
  },
  {
    title: "Applications mobiles",
    icon: "/assets/mobileapp.png",
  },
  {
    title: "Optimisation en Ligne",
    icon: "/assets/optimisation.png",
  },
];

const experiences = [
  {
    title: "Site vitrine professionnel",
    icon: "/assets/logos/medaille.png",
    iconBg: "#E6DEDD",
    offre: "Offre site vitrine",
    points: [
      "• Conception sur mesure pour refléter vous et votre image de marque",
      "• Design responsive pour que les visiteurs aient une expérience agréable sur tous les appareils",
      "• Contenu intégré de qualité pour présenter vos produits et services à merveille",
      "• Création d’un site 100 % personnalisé, en maquette ou avec des templates prêts à l’utilisation, selon vos besoins",
    ],
  },
  {
    title: "Site E-commerce professionnel",
    icon: "/assets/logos/numero2.png",
    iconBg: "#E6DEDD",
    offre: "Offre site E-commerce",
    points: [
      "• Configuration et personnalisation de plateformes e-commerce populaires comme Shopify, WooCommerce ou PrestaShop",
      "• Design responsive pour une expérience d'achat fluide et agréable sur tous les appareils",
      "• Optimisation pour les moteurs de recherche afin d'améliorer votre visibilité en ligne et attirer plus de trafic qualifié",
      "• Intégration de systèmes de paiement sécurisés pour garantir la confiance de vos clients lors de leurs transactions",
      "• Gestion des stocks et des commandes pour faciliter la logistique et le suivi de vos ventes",
    ],
  },
  {
    title: "Application mobile professionnelle",
    icon: "/assets/logos/numero3.png",
    iconBg: "#E6DEDD",
    offre: "Offre application mobile",
    points: [
      "• Design personnalisé et adapté à votre marque",
      "• Compatibilité multiplateforme pour toucher un large public",
      "• Fonctionnalités essentielles pour une utilisation intuitive",
      "• Performances optimisées pour une navigation rapide",
    ],
  },
  {
    title: "Solutions sur mesure",
    icon: "/assets/logos/numero4.png",
    iconBg: "#E6DEDD",
    offre: "Offre personnalisée",
    points: [
      "• Analyse approfondie de vos besoins et objectifs",
      "• Planification stratégique pour un résultat optimal",
      "• Conception et développement adaptés à votre image de marque",
      "• Intégration de fonctionnalités et de technologies pertinentes",
      "• Optimisation pour les moteurs de recherche et la performance",
      "• Support et maintenance pour assurer la pérennité de votre projet",
    ],
  },
];

const testimonials = [
  {
    testimonial:
      "Il m’a été agréable de travailler avec ce developpeur web freelance. Son dévouement et son professionnalisme sont remarquable. Je recommande vivement ses services à qui que ce soit cherchant un spécialiste de développeur web.",
    name: "Phillipe M.",
    designation: "coiffeur",
    company: "Phil el barber",
    image: "/assets/Phillipe.png",
  },
  {
    testimonial:
      "Astra Dev a une formidable capacité d'assimiler rapidement des besoins client et de proposer des solutions efficaces. Cela lui a permis de lancer et de rejoindre son commerce de CBD en laissant tous les doutes techniques qu'il y avait.",
    name: "Laura B.",
    designation: "commercante",
    company: "CBD Malta",
    image: "/assets/Laura.png",
  },
  {
    testimonial:
      "Il est assez rare de trouver quelqu’un qui possède à la fois une bonne maitrise technique ainsi qu’une bonne maitrise des affaires. Astra Dev m'a beaucoup aidé. Je recommande sans réserve.",
    name: "Adam B.",
    designation: "Juriste contentieux",
    company: "Allianz Trade",
    image: "/assets/Adam.png",
  },
];

const projects = [
  {
    name: "Horizon Aventure",
    description:
      "Découvrez Horizon Aventure, une agence de voyages offrant des expériences de voyage sur mesure, des séjours variés et des circuits d'aventure dans le monde entier.",
    tags: [
      {
        name: "react",
        color: "blue-text-gradient",
      },
      {
        name: "html",
        color: "green-text-gradient",
      },
      {
        name: "javascript",
        color: "pink-text-gradient",
      },
    ],
    image: "/assets/agencevoyages.webp",
    source_code_link: "https://horizon-aventure.vercel.app/",
  },
  {
    name: "Ninja Jump",
    description:
      "Plongez dans l'univers de Ninja Jump, un jeu passionnant en JavaScript mettant en scène un ninja intrépide qui doit naviguer habilement entre les plateformes, éviter les adversaires et ramasser des pièces pour atteindre le sommet du classement. Jeu à tester sur ordinateur uniquement.",
    tags: [
      {
        name: "javascript",
        color: "blue-text-gradient",
      },
      {
        name: "html",
        color: "green-text-gradient",
      },
      {
        name: "css",
        color: "pink-text-gradient",
      },
    ],
    image: "/assets/ninja.webp",
    source_code_link: "https://helpful-frangipane-3bd22b.netlify.app/",
  },
  {
    name: "Chabot intelligent basé sur Chat Gpt",
    description:
      "Un assistant virtuel intelligent basé sur l'IA de OpenAI, ChatGPT. Il est capable de comprendre le contexte et de fournir des réponses pertinentes. Idéal pour le service client, l'assistance technique et bien plus encore.",
    tags: [
      {
        name: "Open ai",
        color: "blue-text-gradient",
      },
      {
        name: "Chat Gpt",
        color: "green-text-gradient",
      },
      {
        name: "Chatbot",
        color: "pink-text-gradient",
      },
    ],
    image: "/assets/chatgpt.webp",
    source_code_link: "https://www.youtube.com/watch?v=CaAzJmJuleA",
  },
];

export { services, experiences, testimonials, projects };
