import { useState, useRef, Suspense } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { Points, PointMaterial, Preload } from "@react-three/drei";
import * as THREE from "three";

const Stars = (props) => {
  const ref = useRef();
  const [sphere] = useState(() => {
    const numStars = 5000; // Augmenter le nombre d'étoiles
    const positions = new Float32Array(numStars * 3);
    for (let i = 0; i < positions.length; i += 3) {
      const radius = 1.2;
      const spherical = new THREE.Spherical(
        radius,
        Math.acos(1 - 2 * Math.random()),
        2 * Math.PI * Math.random()
      );
      const vec = new THREE.Vector3().setFromSpherical(spherical);
      positions.set([vec.x, vec.y, vec.z], i);
    }
    return positions;
  });

  useFrame((state, delta) => {
    ref.current.rotation.x -= delta / 20;
    ref.current.rotation.y -= delta / 25;
  });

  return (
    <group rotation={[0, 0, Math.PI / 4]}>
      <Points ref={ref} positions={sphere} stride={3} frustumCulled {...props}>
        <PointMaterial
          transparent
          color="#f272c8"
          size={0.006} // Ajuster la taille des points
          sizeAttenuation={true}
          depthWrite={false}
        />
      </Points>
    </group>
  );
};


const StarsCanvas = () => {
  return (
    <div className='w-full h-auto absolute inset-0 z-[-1]'>
      <Canvas camera={{ position: [0, 0, 1] }}>
        <Suspense fallback={null}>
          <Stars />
        </Suspense>

        <Preload all />
      </Canvas>
    </div>
  );
};

export default StarsCanvas;
