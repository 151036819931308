import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  const iconClasses =
    "relative rounded-full p-4 m-2 w-24 h-24 text-4xl flex justify-center items-center cursor-pointer transform hover:scale-125 transition-all ease-in-out duration-200";

  return (
    <div className="flex flex-col justify-center items-center mt-48 space-y-4">
      <p className="text-white text-xl">Nos réseaux</p>
      <div className="flex justify-center items-center mt-6">
        <a
          href="https://www.facebook.com/people/Astra-dev/100092225066087/"
          target="_blank"
          rel="noopener noreferrer"
          className="icon-link"
        >
          <div className={iconClasses + " icon facebook"}>
            <FontAwesomeIcon icon={faFacebookF} />
          </div>
        </a>
        <a
          href="https://x.com/astradevfr"
          target="_blank"
          rel="noopener noreferrer"
          className="icon-link"
        >
          <div className={iconClasses + " icon twitter"}>
            <FontAwesomeIcon icon={faTwitter} />
          </div>
        </a>
        <a
          href="https://www.instagram.com/astradevfr/"
          target="_blank"
          rel="noopener noreferrer"
          className="icon-link"
        >
          <div className={iconClasses + " icon instagram"}>
            <FontAwesomeIcon icon={faInstagram} />
          </div>
        </a>
      </div>
      <div className="mt-8 text-white text-sm flex space-x-4">
        <a href="/mentions-legales" className="hover:underline">
          Mentions légales
        </a>
        <a href="/politique-de-confidentialite" className="hover:underline">
          Politique de confidentialité
        </a>
        <a href="/conditions-generales-de-vente" className="hover:underline">
          Politique générale de vente
        </a>
        <p>© {new Date().getFullYear()} Astra-Dev, Tous droits réservés.</p>
      </div>
    </div>
  );
};

export default Footer;
