import React, { useState } from "react";
import Tilt from "react-parallax-tilt";
import { motion } from "framer-motion";
import SectionWrapper from "../hoc/SectionWrapper";
import Image from "next/image";
import { styles } from "../styles";
import { services } from "../constants";
import { fadeIn } from "../utils/motion";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const ServiceCard = ({ index, title, icon }) => {
  return (
    <Tilt className="xs:w-[250px] w-full" tiltMaxAngleX={45} tiltMaxAngleY={45}>
      <motion.div
        variants={fadeIn("right", "spring", index * 0.5, 0.75)}
        className="w-full green-pink-gradient p-[1px] rounded-[20px] shadow-card m-2 border-4 border-[#915eff] diagonal-clip"
      >
        <div className="bg-tertiary rounded-[20px] py-5 px-12 min-h-[280px] flex justify-evenly items-center flex-col">
          <Image
            src={icon}
            alt="web-development"
            width={64}
            height={64}
            className="object-contain"
          />
          <p className="text-white text-[20px] font-bold text-center">
            {title}
          </p>
        </div>
      </motion.div>
    </Tilt>
  );
};

// Données pour chaque section de l'accordéon
const textSections = [
  {
    title: "Qui sommes-nous ?",
    content:
      "À Astra Dev, nous proposons des services de développement web freelance, avec des solutions adaptées aux besoins spécifiques de chaque client. Notre approche sur-mesure nous permet d’accompagner chaque client dans la réalisation de sites internet efficaces et optimisés pour le SEO. Profitez de nos services pour faire de votre projet une réussite !",
  },
  {
    title: "Quelles compétences rechercher chez un développeur web freelance ?",
    content:
      "Pour sélectionner un développeur web freelance, il est essentiel qu’il possède les compétences adéquates pour réussir votre projet. La maîtrise de langages comme HTML, CSS, JavaScript, et d’outils tels que React ou WordPress, lui permettra de créer un site tendance. Côté SEO, un bon programmeur saura optimiser le site pour le rendre visible en ligne. Le site doit également être adapté à différents formats d’écran (mobile, tablette, ordinateur). Enfin, des compétences en communication sont essentielles pour bien comprendre vos attentes et assurer une collaboration harmonieuse.",
  },
  {
    title:
      "Un Web designer freelance peut-il assurer la maintenance de mon site ?",
    content:
      "Nous avons tous un site web, et un web designer peut s’en occuper en maintenant les contenus à jour et en ajoutant de nouveaux visuels. Cela aide à capter l’attention des visiteurs. Mais sans compétences techniques avancées, il ne pourra pas toucher au code ni résoudre des problèmes de base de données si des modifications plus techniques sont nécessaires. Par exemple, en cas d’erreurs de code, les corrections pourraient être compliquées. Ce qui est intéressant en utilisant un CMS (autre que Drupal ou Joomla) est que certaines tâches restent simples. Vous pourrez gérer vous-même les plugins et les mises à jour, même si des changements plus complexes nécessiteront parfois l’aide d’un développeur web freelance.",
  },
  // Ajoute d’autres sections ici
];

const About = () => {
  const [openSections, setOpenSections] = useState(
    Array(textSections.length).fill(false)
  );

  // Fonction pour basculer l'état ouvert/fermé d'une section
  const toggleSection = (index) => {
    setOpenSections((prevSections) => {
      const newSections = [...prevSections];
      newSections[index] = !newSections[index];
      return newSections;
    });
  };

  return (
    <>
      <motion.div variants={fadeIn("", "", 0.1, 1)} className="text-center">
        <p className={styles.sectionSubText}>Travailler Avec Nous</p>
        <p className="text-[28px] font-bold mb-4 text-white">À propos :</p>
      </motion.div>

      {/* Accordéon SEO-friendly */}
      <div className="mt-8 max-w-4xl mx-auto mb-8">
        {textSections.map((section, index) => (
          <div key={index} className="border-b border-gray-300 mb-4">
            <button
              onClick={() => toggleSection(index)}
              className="flex justify-between items-center w-full text-left py-4"
            >
              <h2 className="text-[24px] font-bold text-white">
                {section.title}
              </h2>
              <span className="text-white">
                {openSections[index] ? (
                  <FaChevronUp size={24} />
                ) : (
                  <FaChevronDown size={24} />
                )}
              </span>
            </button>
            {openSections[index] && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
                className="p-4 bg-white text-black rounded-b-lg shadow-md"
              >
                <p className="text-black">{section.content}</p>
              </motion.div>
            )}
          </div>
        ))}
      </div>

      <div className="mt-20 flex flex-wrap gap-10" id="services">
        {services.map((service, index) => (
          <ServiceCard key={service.title} index={index} {...service} />
        ))}
      </div>
    </>
  );
};

export default SectionWrapper(About, "histoire");
