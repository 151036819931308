import React, { useEffect, useState } from "react";
import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/router";
import { styles } from "../styles";

// Liste de navigation, incluant un lien vers le blog
const navLinks = [
  { id: "histoire", title: "Notre histoire" },
  { id: "services", title: "Services" },
  { id: "contact", title: "Contact" },
  { id: "blog", title: "Blog" },
];

const Navbar = () => {
  const [active, setActive] = useState("");
  const [toggle, setToggle] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setScrolled(scrollTop > 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleNavigation = (id) => {
    if (id === "blog") {
      router.push("/blog"); // Redirection directe vers la page blog
    } else {
      if (router.pathname === "/") {
        // Sur la page d'accueil, utiliser smooth scroll
        document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
      } else {
        // Sinon, redirige vers la page d'accueil avec l'ancre
        router.push(`/#${id}`);
      }
      setActive(id); // Mettre à jour l'état actif pour le lien sélectionné
      setToggle(false); // Ferme le menu sur mobile
    }
  };

  return (
    <nav
      className={`${
        styles.paddingX
      } w-full flex items-center py-5 fixed top-0 z-20 ${
        scrolled ? "bg-primary" : "bg-transparent"
      }`}
    >
      <div className="w-full flex justify-between items-center max-w-7xl mx-auto">
        <Link href="/" legacyBehavior>
          <a
            className="flex items-center gap-2"
            onClick={() => {
              setActive("");
              window.scrollTo(0, 0);
            }}
          >
            <Image
              src="/logo.svg"
              alt="logo"
              width={36}
              height={36}
              className="object-contain"
            />
            <p className="text-white text-[18px] font-bold cursor-pointer flex">
              Astra Dev &nbsp;
              <span className="sm:block hidden">
                {" "}
                | developpeur Web Freelance
              </span>
              <Image
                src="/Rocket_Ship.gif"
                alt="Animated gif"
                width={40}
                height={40}
                unoptimized
              />
            </p>
          </a>
        </Link>

        <ul className="list-none hidden sm:flex flex-row gap-10">
          {navLinks.map((nav) => (
            <li
              key={nav.id}
              className={`${
                active === nav.title ? "text-red" : "text-quartenary"
              } hover:text-white text-[18px] font-medium cursor-pointer`}
              onClick={() => handleNavigation(nav.id)}
            >
              <span>{nav.title}</span>
            </li>
          ))}
        </ul>

        <div className="sm:hidden flex flex-1 justify-end items-center">
          <Image
            src="/assets/menu.svg"
            alt="menu"
            width={28} // Taille exacte définie
            height={28} // Taille exacte définie
            className="object-contain mr-2 h-auto w-auto" // Maintient le ratio d'aspect
            onClick={() => setToggle(!toggle)}
          />

          <div
            className={`${
              !toggle ? "hidden" : "flex"
            } p-6 black-gradient absolute top-20 right-0 mx-4 my-2 min-w-[140px] z-10 rounded-xl`}
          >
            <ul className="list-none flex justify-end items-start flex-1 flex-col gap-4">
              {navLinks.map((nav) => (
                <li
                  key={nav.id}
                  className={`font-poppins font-medium cursor-pointer text-[16px] ${
                    active === nav.title ? "text-white" : "text-secondary"
                  }`}
                  onClick={() => handleNavigation(nav.id)}
                >
                  <span>{nav.title}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
