// pages/index.js
import Head from "next/head";
import Navbar from "../src/components/Navbar";
import Footer from "../src/components/Footer";

import {
  About,
  Contact,
  Experience,
  Feedbacks,
  Hero,
  Works,
  Faq,
} from "../src/components";
import Stars from "../src/components/canvas/Stars";
import WorkProcess from "../src/components/WorkProcess";
import CallToAction from "../src/components/CallToAction";

export default function HomePage() {
  // Données structurées pour WebPage
  const jsonLdWebPage = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "Astra Dev - Développeur Web Freelance",
    description:
      "Astra Dev, développeur web freelance spécialisé dans la création de sites internet professionnels, vitrines, e-commerce et applications web. Optimisez votre présence en ligne avec des solutions adaptées et un SEO performant.",
    url: "https://astra-dev.fr",
    potentialAction: {
      "@type": "SearchAction",
      target: "https://astra-dev.fr/search?q={search_term_string}",
      "query-input": "required name=search_term_string",
    },
  };

  // Données structurées pour Organization
  const jsonLdOrganization = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Astra Dev",
    url: "https://astra-dev.fr",
    logo: "https://astra-dev.fr/logo.png",
    description:
      "Agence web spécialisée en création de sites internet et SEO sur mesure pour entreprises.",
    sameAs: ["https://x.com/astradevfr"],
    contactPoint: [
      {
        "@type": "ContactPoint",
        telephone: "+33-6-1234-5678",
        contactType: "Service Client",
        areaServed: "FR",
        availableLanguage: ["French", "English"],
      },
    ],
  };

  // Données structurées pour un service principal
  const jsonLdService = {
    "@context": "https://schema.org",
    "@type": "Service",
    name: "Création de sites internet sur mesure",
    provider: {
      "@type": "Organization",
      name: "Astra Dev",
    },
    description:
      "Service de création de sites internet professionnels pour améliorer la présence en ligne des entreprises.",
    areaServed: "France",
    audience: {
      "@type": "Audience",
      audienceType: "Professionnels, TPE/PME",
    },
    providerMobility: "static",
    termsOfService: "https://astra-dev.fr/mentions-legales",
  };

  // Données structurées pour une section FAQ
  const jsonLdFAQ = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "Quels services propose Astra Dev ?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Astra Dev propose des services de création de sites internet, optimisation SEO, et développement d'applications web.",
        },
      },
      {
        "@type": "Question",
        name: "Pourquoi choisir un développeur web freelance ?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Un développeur web freelance offre des solutions personnalisées et une grande flexibilité pour répondre à vos besoins spécifiques.",
        },
      },
    ],
  };

  return (
    <div className="homepage-root relative z-0">
      <Head>
        <title>Astra Dev - Développeur Web Freelance</title>
        <meta
          name="description"
          content="Astra Dev, développeur web freelance spécialisé dans la création de sites internet professionnels, vitrines, e-commerce et applications web. Optimisez votre présence en ligne avec des solutions adaptées et un SEO performant."
        />
        <meta name="robots" content="index, follow" />
        <meta
          name="keywords"
          content="développeur web freelance, création de sites internet, SEO, site vitrine, site e-commerce, optimisation web, Astra Dev, développeur freelance"
        />
        <link rel="canonical" href="https://astra-dev.fr" />
        <meta
          property="og:title"
          content="Astra Dev - Développeur Web Freelance"
        />
        <meta
          property="og:description"
          content="Développeur web freelance spécialisé dans la création de sites internet sur mesure, avec un focus sur l'optimisation SEO et la performance."
        />
        <meta property="og:image" content="https://astra-dev.fr/galaxy2.jpg" />
        <meta property="og:url" content="https://astra-dev.fr" />
        <meta name="twitter:card" content="summary_large_image" />

        {/* Données structurées */}
        <script type="application/ld+json">
          {JSON.stringify(jsonLdWebPage)}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(jsonLdOrganization)}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(jsonLdService)}
        </script>
        <script type="application/ld+json">{JSON.stringify(jsonLdFAQ)}</script>
      </Head>

      <Navbar />
      <div className="relative">
        <img
          src="/galaxy2.webp"
          alt="Illustration de l'espace pour Astra Dev"
          className="absolute inset-0 object-cover w-full h-full"
          loading="eager" // Chargement prioritaire pour éviter l'avertissement
          fetchpriority="high" // Indique que cette ressource est critique
          width="1920" // Largeur réelle de l'image pour éviter les calculs inutiles
          height="1080" // Hauteur réelle de l'image
          aria-hidden="true"
        />
        <div className="relative z-10">
          <Hero />
        </div>
      </div>
      <div className="relative z-0 bg-gradient-to-br bg-space-blue">
        <About />
        <Experience />
        <Works />
        <WorkProcess />
        <Feedbacks />
        <Faq />
        <div className="relative z-0 bg-primary">
          <CallToAction />
          <Contact />
          <Footer />
          <Stars />
        </div>
      </div>
    </div>
  );
}
