import React from "react";
import Tilt from "react-parallax-tilt"; // Utilisation de react-parallax-tilt
import { motion } from "framer-motion";
import Image from "next/image"; // Importer le composant Image de Next.js

import { styles } from "../styles";
import SectionWrapper from "../hoc/SectionWrapper";
import { projects } from "../constants";
import { fadeIn, textVariant } from "../utils/motion";

const ProjectCard = ({
  index,
  name,
  description,
  tags,
  image,
  source_code_link,
}) => {
  return (
    <motion.div variants={fadeIn("up", "spring", index * 0.5, 0.75)}>
      <Tilt
        tiltMaxAngleX={45}
        tiltMaxAngleY={45}
        scale={1}
        transitionSpeed={450}
        className="bg-tertiary p-5 rounded-2xl sm:w-[360px] w-full"
      >
        <div
          className="relative w-full h-auto overflow-hidden"
          style={{ aspectRatio: "16/10" }}
        >
          <Image
            src={image}
            alt={`${name} project image`}
            fill
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 360px"
            className="rounded-2xl object-cover"
            priority={index === 0}
          />
          <div className="absolute inset-0 flex justify-end m-3 card-img_hover">
            <div
              onClick={() => window.open(source_code_link, "_blank")}
              className="black-gradient w-10 h-10 rounded-full flex justify-center items-center cursor-pointer"
            >
              <Image
                src="/assets/click.png"
                alt="source code"
                width={20}
                height={20}
                className="object-contain"
                style={{ backgroundColor: "white", borderRadius: "50%" }}
              />
            </div>
          </div>
        </div>

        <div className="mt-5">
          <h3 className="text-white font-bold text-[24px]">{name}</h3>
          <p className="mt-2 text-quartenary text-[14px]">{description}</p>
        </div>

        <div className="mt-4 flex flex-wrap gap-2">
          {tags.map((tag) => (
            <p
              key={`${name}-${tag.name}`}
              className={`text-[14px] ${tag.color}`}
            >
              #{tag.name}
            </p>
          ))}
        </div>
      </Tilt>
    </motion.div>
  );
};

const Works = () => {
  return (
    <>
      <motion.div id="projects" variants={textVariant()}>
        <p className={`${styles.sectionSubText} `}>Exemples de différents</p>
        <h2 className={`${styles.sectionHeadText}`}>Projets.</h2>
      </motion.div>

      <div className="w-full flex">
        <motion.p
          variants={fadeIn("", "", 0.1, 1)}
          className="mt-3 text-white text-[17px] max-w-3xl leading-[30px]"
        >
          Regardez nos projets sélectionnés pour voir l'étendue de notre savoir
          faire en matière de développement web. Les trois exemples donnés sont
          un aperçu des projets possibles. Chaque semaine, nous réalisons des
          Applications Web, nous créons des jeux vidéo innovants et nous mettons
          en place des systèmes des support intelligents avec ChatGPT. Nous
          sommes à l'aise en full stack que ce soit front end ou back end.
        </motion.p>
      </div>

      <div className="mt-20 flex flex-wrap gap-7">
        {projects.map((project, index) => (
          <ProjectCard key={`project-${index}`} index={index} {...project} />
        ))}
      </div>
    </>
  );
};

export default SectionWrapper(Works, "");
