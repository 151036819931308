import React, { useRef, useState, useEffect } from "react";
import Image from "next/image"; // Importer Image de Next.js pour optimisation

const WorkProcessCard = ({
  logo,
  title,
  description,
  backgroundImage,
  backgroundOverlayColor,
}) => {
  const [isInView, setIsInView] = useState(false);
  const cardRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
        }
      },
      { threshold: 0.1 }
    );
    if (cardRef.current) observer.observe(cardRef.current);
    return () => observer.disconnect();
  }, []);

  return (
    <div
      ref={cardRef}
      className="work-process-step text-center p-10 rounded-3xl overflow-hidden"
      style={{
        backgroundImage: isInView
          ? `linear-gradient(${backgroundOverlayColor}, ${backgroundOverlayColor}), url(${backgroundImage})`
          : "none", // Aucun fond tant que l'élément n'est pas dans le viewport
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="logo-wrapper">
        {/* Utilisation de Next.js Image pour le logo */}
        <Image
          src={logo}
          alt={`Logo ${title}`}
          width={48}
          height={48}
          loading="lazy"
          className="w-12 h-12 object-contain"
          onError={() =>
            console.log(`Erreur de chargement pour l'image: ${logo}`)
          }
        />
      </div>
      <h3 className="text-2xl font-bold mb-4">{title}</h3>
      <p className="text-white text-lg font-semibold font-sans">
        {description}
      </p>
    </div>
  );
};

const WorkProcess = () => {
  const workProcessSteps = [
    {
      logo: "/assets/logos/medaille.png",
      title: "Analyse",
      description:
        "Nous commençons par comprendre votre entreprise, vos besoins afin d'en connaitre vos objectifs et concevoir la meilleure stratégie possible pour votre projet.",
      backgroundImage: "/assets/red.webp",
      backgroundOverlayColor: "rgba(0, 0, 50, 0.7)",
    },
    {
      logo: "/assets/logos/numero2.png",
      title: "Conception",
      description:
        "Nos designers créent des maquettes uniques et qui sont adaptées à votre image de marque afin d’assurer une expérience utilisateur optimale sur tous les appareils.",
      backgroundImage: "/assets/circuits.webp",
      backgroundOverlayColor: "rgba(0, 0, 50, 0.9)",
    },
    {
      logo: "/assets/logos/numero3.png",
      title: "Développement",
      description:
        "Les maquettes sont ensuite transformées par un développeur web freelance en un site web fonctionnel et performant, au travers des nouvelles technologies et normes du marché.",
      backgroundImage: "/assets/code.webp",
      backgroundOverlayColor: "rgba(0, 0, 50, 0.9)",
    },
    {
      logo: "/assets/logos/numero4.png",
      title: "Lancement",
      description:
        "Après avoir testé et revu le site web, nous le lançons et le mettons en ligne. Nous suivons et vous aidons également à assurer son succès.",
      backgroundImage: "/assets/rocket.webp",
      backgroundOverlayColor: "rgba(0, 0, 0, 0.7)",
    },
  ];

  return (
    <section className="work-process py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-10">
          Processus de création de site internet
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {workProcessSteps.map((step, index) => (
            <WorkProcessCard key={index} {...step} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default WorkProcess;
