import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";

const faqData = [
  {
    question: "Combien de temps faut-il pour créer un site web ?",
    answer:
      "En général, la mise en place d’un site web peut durer entre quelques semaines et plusieurs mois selon les fonctionnalités que vous souhaitez mettre en place sur votre site et vos objectifs. En quelques semaines, on peut faire un site vitrine ou simplement un blog. Avec un CMS comme WordPress, tout est plus rapide, surtout avec un template personnalisé et des fonctionnalités prêtes à l’emploi. Si par exemple vous avez un projet plus complexe ou un site e commerce le délai est beaucoup plus long. Chaque fonctionnalité supplémentaire prend du temps à la conception, au test, à l'intégration.",
  },
  {
    question: "Quel est le prix d’un site internet ?",
    answer:
      "Le prix d'un site internet dépend de sa complexité , des fonctionnalités ou services recherchés. Un simple site vitrine, avec des fonctionnalités de base, aura généralement un coût de 300 à 1 500€. Pour un site e-commerce ou sur mesure, avec des options avancées (gestion des stocks, paiement sécurisé, personnalisation) : le cout peut etre de 2 500€ a plus de 5000€ . La quantité de produits en vente est également une variante importe sur le calcul total du projet.  D’autres frais sont a prendre en compte tels que : le nom de domaine (10€ à 20€ par an) et l’hébergement (5€ à 30€ par mois).",
  },
  {
    question:
      "Que se passe-t-il si j’ai besoin de modifier du contenu sur mon site?",
    answer:
      "Si votre site est crée sur WordPress, vous pouvez facilement effectuer les changements de contenu, tels que la création d'articles, incorporer des modifications texte ou de mise à jour des images, seul. Wordpress est accessible même pour celles et ceux sans compétences techniques avancés. Par contre si votre site est codé sur mesure (hors CMS), il y aura certaines modifications qui nécessiteront l’aide d’un développeur web freelance.",
  },
  {
    question:
      "Comment savoir de quelles fonctionnalités j'ai besoin sur mon site web?",
    answer:
      "Avant de commencer à définir les fonctionnalités nécessaires sur votre site web, il vous est nécessaire de saisir vos objectifs principaux. Par exemple : voulez vous vendre des produits ? Présenter vos services ? Recueillir des contacts ? Pour un site vitrine, une page de contact et un formulaire de contact peuvent parfois suffire. Si vous avez un site e-commerce, vous aurez probablement besoin d’un système de paiement sécurisé, d’une gestion de stock et le suivi des commandes. Mais si vous hésitez, nous pourrons répondre a vos questions sur les fonctionnalités essentielles en fonction votre secteur d’activité et votre objectif de croissance.",
  },
];

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="faq-section py-8 px-4 md:px-8 lg:px-16">
      <h2 className="text-center text-xl md:text-2xl font-semibold mb-6">
        Questions Fréquentes
      </h2>
      <div className="space-y-4 max-w-2xl mx-auto">
        {faqData.map((item, index) => (
          <div key={index} className="border rounded-lg shadow-sm">
            <button
              onClick={() => toggleFAQ(index)}
              className="w-full flex items-center justify-between p-3 text-left text-md font-medium focus:outline-none"
            >
              <h3 className="text-sm md:text-base font-semibold tracking-wide text-[#f5f5f5]">
                {item.question}
              </h3>
              <span>
                {openIndex === index ? (
                  <FaChevronUp size={18} />
                ) : (
                  <FaChevronDown size={18} />
                )}
              </span>
            </button>
            <AnimatePresence>
              {openIndex === index && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.3, ease: "easeInOut" }}
                  className="p-4"
                >
                  <p className="text-white text-sm md:text-base">
                    {item.answer}
                  </p>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
