import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { motion } from "framer-motion";
import Image from "next/image";

import { styles } from "../styles";
import { experiences } from "../constants";
import SectionWrapper from "../hoc/SectionWrapper";

import { textVariant } from "../utils/motion";

const ExperienceCard = ({ experience }) => {
  return (
    <VerticalTimelineElement
      className="vertical-timeline-element"
      contentStyle={{
        background: "transparent",
        boxShadow: "none",
      }}
      contentArrowStyle={{ borderRight: "7px solid  #232631" }}
      date={experience.offre}
      iconStyle={{ background: experience.iconBg }}
      icon={
        <div className="flex justify-center items-center w-full h-full">
          <Image
            src={experience.icon}
            alt={experience.title}
            width={40}
            height={40}
            loading="lazy"
            className="object-contain"
            style={{ width: "auto", height: "auto" }} // Maintient le ratio d'aspect
          />
        </div>
      }
    >
      <div className="card-container">
        <h3 className="card-header">{experience.title}</h3>
        <p className="card-subheader">{experience.company_name}</p>
        <ul className="mt-5 list-disc ml-5 space-y-2">
          {experience.points.map((point, index) => (
            <li
              key={`experience-point-${index}`}
              className="text-white-100 text-[14px] pl-1 tracking-wider"
            >
              {point}
            </li>
          ))}
        </ul>
      </div>
    </VerticalTimelineElement>
  );
};

const Experience = () => {
  return (
    <>
      <motion.div
        initial="hidden"
        animate="show"
        variants={textVariant(0)} // Spécifie un délai par défaut
      >
        <p className={`${styles.sectionSubText} text-center`}>
          Ce que nous proposons
        </p>
        <h2 className={`${styles.sectionHeadText} text-center`}>Nos offres</h2>
      </motion.div>

      <div className="mt-20 flex flex-col">
        <VerticalTimeline>
          {experiences.map((experience, index) => (
            <ExperienceCard
              key={`experience-${index}`}
              experience={experience}
            />
          ))}
        </VerticalTimeline>
      </div>
    </>
  );
};

export default SectionWrapper(Experience, "services");
